.loginbox{
    /* position: absolute !important;
    right:20 !important; */
    width: 25%;
    top: 20%;
    right: 7%;
    position: absolute;
}
.registerbox{
    /* position: absolute !important;
    right:20 !important; */
    width: 25%;
    top: 20%;
    right: 7%;
    position: absolute;
}